import { component } from 'picoapp'
import { add } from 'martha'

export default component((node, ctx) => {
  ctx.on('load:complete', () => {
    setTimeout(() => {
      add(node, 'translate-x-[calc(-50vw-100%)]')
      add(node, 'translate-y-[-70rem]')
    }, 1000)
  })
})
