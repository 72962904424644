import { picoapp } from 'picoapp'
import { size, qs } from 'martha'

import lazy from './components/lazy'
import scroll from './components/scroll'
import sticky from './components/sticky'
import shootingStar from './components/shootingStar'
import sky from './components/sky'
import scrollTrigger from './components/scrollTrigger'
import modal from './components/modal'
import nav from './components/nav'
import portfolio from './components/portfolio'
import loader from './components/loader'
import planets from './components/planets'
import carousel from './components/carousel'

const components = {
  lazy,
  scroll,
  sticky,
  shootingStar,
  sky,
  scrollTrigger,
  modal,
  nav,
  portfolio,
  loader,
  planets,
  carousel
}

const sizes = size()
const state = {
  ...sizes,
  mx: sizes.ww / 2,
  my: sizes.wh / 2,
  dom: {
    html: document.documentElement,
    body: document.body,
    scrollProxy: qs('.js-scroll-proxy'),
  },
  fonts: [
    // { family: 'GT Walsheim' },
    // { family: 'GT Walsheim', options: { weight: 300 } },
    // { family: 'GT Walsheim', options: { weight: 300, style: 'italic' } },
  ],
}

export default picoapp(components, state)
