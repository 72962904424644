import { component } from 'picoapp'
import { add, remove, on, qs } from 'martha'
import choozy from 'choozy'

export default component((node, ctx) => {
  let { openModal, content } = choozy(node)
  let closeModal = qs('.js-closeModal')

  on(openModal, 'click', () => {
    add(document.body, 'modal-open')
    qs('.modal-content').innerHTML = content.innerHTML
  })

  on(closeModal, 'click', () => {
    remove(document.body, 'modal-open')
  })

  return () => {}
})
