import { component } from 'picoapp'
import { on, add, remove, qs } from 'martha'
import choozy from 'choozy'
import { gsap } from 'gsap/all'

export default component((node, ctx) => {
  let { saturn } = choozy(node)

  remove(document.body, 'loading')
  ctx.emit('scrolltrigger:refresh')
  // return null;

  add(document.body, 'loading')
  
  setTimeout(() => {
    ctx.emit('scrolltrigger:refresh')
  }, 1000);

  // if (sessionStorage.getItem('loaded') == 'true' || window.location.pathname != '/') {
  if (sessionStorage.getItem('loaded') == 'true') {
    add(node, 'opacity-0')
    remove(document.body, 'loading')
    ctx.emit('load:complete')

    gsap.to('.hero-element', {
      y: 0,
      ease: 'elastic.out',
      stagger: 0.05,
      duration: 1,
    })
    // node.remove()
    return
  }

  //set hero elements as transparent
  gsap.set('.hero-element', { y: 50 })

  //initialize loader after planet has loaded
  on(saturn, 'load', () => {
    remove(saturn, 'opacity-0 translate-y-full')

    gsap.fromTo(
      saturn,
      { scale: 0.1 },
      {
        scale: 1,
        duration: 3,
        ease: 'expo.inOut',
        onComplete: () => {
          add(node, 'opacity-0')
          remove(document.body, 'loading')
          ctx.emit('load:complete')

          ctx.emit('scrolltrigger:refresh')
          gsap.to('.hero-element', {
            y: 0,
            ease: 'elastic.out',
            stagger: 0.05,
            duration: 1,
          })
        },
      },
    )
  })

  return () => {}
})
