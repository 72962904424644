import { component } from 'picoapp'
import { add, on } from 'martha'
import choozy from 'choozy'

export default component((node, ctx) => {
  let { sky } = choozy(node)
  let documentHeight = document.body.offsetHeight

  const offScroll = on(document, 'scroll', (e) => {
    let scrollDistance =
      window.pageYOffset + document.documentElement.clientHeight

    let scrollPercentage = (scrollDistance / documentHeight) * 100
    sky.style.transform = `translateY(-${scrollPercentage}%)`
  })

  const offSize = () => {
    on(window, 'resize', () => {
        documentHeight = document.body.offsetHeight
    })
  }

  return () => {
    offScroll()
    offSize()
  }
})
