import { component } from "picoapp";
import choozy from "choozy";
import { remove, add, qs, on, qsa } from "martha";
import EmblaCarousel from "embla-carousel";

export default component((node, ctx) => {
  let { carousel, next, prev, counter } = choozy(node);

  const embla = EmblaCarousel(carousel, { loop: true, skipSnaps: false });

  if (next && prev) {
    prev.addEventListener("click", embla.scrollPrev, false);
    next.addEventListener("click", embla.scrollNext, false);
  }
});
