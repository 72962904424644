import { component } from 'picoapp'
import { add, remove, on, has } from 'martha'
import choozy from 'choozy'
import { gsap, ScrollTrigger, ScrollToPlugin } from 'gsap/all'

export default component((node, ctx) => {
  let { waiter } = choozy(node)
  gsap.registerPlugin(ScrollTrigger)

  gsap.utils.toArray('section').forEach((section) => {
    ScrollTrigger.create({
      trigger: section,
      start:
        section.dataset.section == 'hero'
          ? 'center center'
          : section.dataset.section == 'end' ||
            section.dataset.section == 'about'
          ? 'top bottom'
          : 'top center',
      end:
        section.dataset.section == 'hero'
          ? 'bottom center'
          : section.dataset.section == 'about'
          ? 'center center-=10%'
          : section.dataset.section == 'end'
          ? 'bottom -=150'
          : 'bottom top',
      onUpdate: (self) => {
        if (section.dataset.section == 'hero') {
          if (self.progress < 0.25) {
            waiter.dataset.sequence = 1
          } else if (self.progress >= 0.25 && self.progress < 0.5) {
            waiter.dataset.sequence = 2
          } else if (self.progress >= 0.5 && self.progress < 0.75) {
            waiter.dataset.sequence = 3
          } else {
            waiter.dataset.sequence = 4
          }
        }

        if (section.dataset.section == 'about') {
          if (self.progress < 0.75) {
            section.dataset.sequence = 1
          } else if (self.progress >= 0.75 && self.progress < 0.84) {
            section.dataset.sequence = 2
          } else if (self.progress >= 0.84 && self.progress < 0.92) {
            section.dataset.sequence = 3
          } else {
            section.dataset.sequence = 4
          }
        }
      },
      onEnter: () => {
        if (section.dataset.section == 'manifesto') {
          add(section, 'triggered')
        }

        if (
          section.dataset.section == 'portfolio' &&
          !has(section, 'triggered')
        ) {
          gsap.fromTo(
            '.js-frame:not(.hidden)',
            { opacity: 0, y: 40 },
            { opacity: 1, y: 0, stagger: 0.1, duration: 1, ease: 'expo.out' },
          )
        }

        if (
          section.dataset.section == 'team' &&
          !has(section, 'triggered')
        ) {
          gsap.fromTo(
            '.js-team:not(.hidden)',
            { opacity: 0, y: 40 },
            { opacity: 1, y: 0, stagger: 0.1, duration: 1, ease: 'expo.out' },
          )
        }
      },
      onToggle: (self) => {
        if (self.progress > 0 && self.progress < 1) {
          add(section, 'in-view')
        } else {
          remove(section, 'in-view')
        }
      },
    })
  })

  ctx.on('scrolltrigger:refresh', () => {
    ScrollTrigger.refresh()
  })

  return () => {}
})
