import { component } from 'picoapp'
import { add, remove, on } from 'martha'
import choozy from 'choozy'
import { gsap, ScrollToPlugin } from 'gsap/all'

export default component((node, ctx) => {
  let { btn, menu, menuOpen, menuClose } = choozy(node)

  gsap.registerPlugin(ScrollToPlugin)

  on(btn, 'click', (e) => {
    let section = e.target.dataset?.section

    remove(document.body, 'menu-open')

    if (section) {
      gsap.to(window, {
        duration: 1,
        scrollTo: {
          y: `section[data-section="${section}"]`,
          offsetY: section == 'about' ? 200 : 0,
        },
        ease: 'expo.out',
      })
    }
  })

  on(menuOpen, 'click', () => {
    add(document.body, 'menu-open')
  })

  on(menuClose, 'click', () => {
    remove(document.body, 'menu-open')
  })

  return () => {}
})
