import { component } from 'picoapp'
import { add, remove, on, qsa, qs } from 'martha'
import choozy from 'choozy'
import gsap from 'gsap'

export default component((node, ctx) => {
  let { loadMore } = choozy(node)

  on(loadMore, 'click', () => {
    let frames = qsa('.js-frame.hidden', node)
    if (frames.length <= 12) {
      loadMore.remove()
    }

    frames.forEach((el, index) => {
      if (index <= 11) {
        console.log('index', index)

        remove(el, 'hidden opacity-0 translate-y-40')
      }
    })

    ctx.emit('scrolltrigger:refresh')
  })

  let frameBtns = qsa('button.js-frame', node)
  let closeModal = qs('.js-closeModal')

  frameBtns.forEach((btn) => {
    on(btn, 'click', () => {
      add(document.body, 'modal-open')
      qs('.modal-content').innerHTML = qs('.frame-content', btn).innerHTML
    })
  })

  on(closeModal, 'click', () => {
    remove(document.body, 'modal-open')
  })

  return () => {}
})
