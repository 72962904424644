import { component } from "picoapp";
import choozy from "choozy";
import { remove, add, qs, on, qsa } from "martha";
import EmblaCarousel from "embla-carousel";

export default component((node, ctx) => {
  let { carousel, next, prev, investment } = choozy(node);

  const embla = EmblaCarousel(carousel, { loop: true, skipSnaps: false });

  if (next && prev) {
    prev.addEventListener("click", embla.scrollPrev, false);
    next.addEventListener("click", embla.scrollNext, false);
  }

  embla.on("select", () => {
    remove(qs(`.js-investment.is-selected`, node), "is-selected");
    add(
      qs(`.js-investment[data-index="${embla.selectedScrollSnap() + 1}"]`, node),
      "is-selected"
    );
  });

  investment.forEach((item) => {
    console.log('item', item);
    on(item, 'click', (e) => {
      console.log('e', e);
      embla.scrollTo(e.target.dataset.index - 1)
    })
  })
});
